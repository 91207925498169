import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconDefinition, faCamera, faArrowCircleLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/shared/models/user.model';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { PatronListSaver } from 'src/app/services/utils/patron-list-saver.service';
import { MatDialog } from '@angular/material/dialog';
import { ClientListDialogComponent } from '../../components/manage-user/client-list-dialog/client-list-dialog-component';
import { UserTypeEnum } from '../../components/manage-user/mock-data/user-type.enum';
import { get } from 'lodash';
import { AdminsListService } from '../../menus/managers-and-admins-menu/admins-list/admins-list.service';
import { ManagersListService } from '../../menus/managers-and-admins-menu/managers-list/managers-list.service';
import {
  AdminManager,
  DataManager,
  ManagerActions,
  ManagerTypes,
  OrganizationManager,
  UsersType,
} from 'src/app/shared/interfaces/Manager.interface';
import { Organization, Patron } from 'src/app/core/openapi';
import { CloudnaryService } from 'src/app/services/cloudnary/cloudnary.service';

@Component({
  selector: 'org-admin-manager-create-modal',
  templateUrl: './org-admin-manager-create-modal.component.html',
  styleUrls: ['./org-admin-manager-create-modal.component.scss'],
})
export class OrgAdminAndManagerCreateModalComponent implements OnInit {
  @ViewChild('fullAccessInput') fullAccessInput: ElementRef;

  public readonly camera: IconDefinition = faCamera;
  public readonly faBack: IconDefinition = faArrowCircleLeft;
  public readonly spinnerIcon: IconDefinition = faSpinner;

  public orgAcc: User;
  public org: Organization;
  public stateSave: boolean = false;
  public patrons: Patron[] = [];

  public organizationAdminAndManagerForm: OrganizationManager | AdminManager = {
    fullname: '',
    nickname: '',
    type: '',
    email: '',
    image: '',
    allowFullAccess: true,
    allowedClients: [],
    verifyType: '',
    org: '',
    subdomain: '',
    orgEmail: '',
    lang: '',
    familyName: '',
    givenName: '',
  };

  public userType: string;
  public isFullAccessChecked: boolean = false;
  public isSchool: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataManager,
    public dialogRef: MatDialogRef<OrgAdminAndManagerCreateModalComponent>,
    private _snackBar: MatSnackBar,
    private _rest: RestAPIService,
    private _roles: RoleService,
    private patronListSaver: PatronListSaver,
    private dialog: MatDialog,
    private _adminsListService: AdminsListService,
    private _managersListService: ManagersListService,
    private cloudinary: CloudnaryService,
  ) {
    this.initModalConfig(this.data);
  }

  ngAfterViewInit() {
    if (this.data?.type === ManagerActions.UPDATE && this.userType === UsersType.MANAGER) {
      const isSettings = get(this.data, 'settings', false);
      if (!isSettings) {
        return;
      }
      setTimeout(() => {
        this.fullAccessInput.nativeElement.checked = this.isFullAccessChecked;
      });
    }
  }

  async ngOnInit() {
    await this.whoAmI();
    // This if is used to check the type. If the type does not exist, it is assumed that the user is a Patron, eliminating the need to make a call to fetch Patron data.
    if (this.data?.admin?.type || this.data?.manager?.type) {
      this.patrons = await this.patronListSaver.getOrganizationPatronList();
    }
  }

  private initModalConfig(data: DataManager) {
    if (data?.type === ManagerActions.UPDATE) {
      const user = this.data?.manager || this.data?.admin;
      const userType = get(user, 'type', get(user, 'role', '')).toLowerCase();
      this.organizationAdminAndManagerForm = {
        image: get(user, 'patron.image', get(user, 'image', '')),
        fullname: `${get(user, 'patron.givenName', get(user, 'name', ''))} ${get(user, 'patron.familyName', '')}`,
        nickname: get(user, 'patron.nickname', ''),
        email: get(user, 'email', ''),
        ...(userType === UsersType.MANAGER ? { allowFullAccess: user?.patron?.allowFullAccess } : {}),
      } as OrganizationManager | AdminManager;

      this.isFullAccessChecked = this.organizationAdminAndManagerForm.allowFullAccess;
      this.userType = userType ? userType : UsersType.ADMIN;
    }

    if (data?.type === ManagerActions.CREATE && (data?.manager?.type || data?.admin?.type)) {
      this.userType = get(data, 'manager.type', get(data, 'admin.type', '')).toLowerCase();
    }
  }

  private async whoAmI() {
    this.orgAcc = this._roles.orgAcc;
    this.isSchool = get(this.orgAcc, 'organization.isSchool', false);
    const response = await this._rest.get('organization/self', { msg: 'Could not get organization.' });
    if (response) {
      this.org = response?.organization;
    }
  }

  async save() {
    switch (this.data?.type) {
      case ManagerActions.CREATE:
        this.stateSave = true;
        await this.create();
        this.stateSave = false;
        break;

      case ManagerActions.UPDATE:
        this.stateSave = true;
        await this.update();
        this.stateSave = false;
        break;

      default:
        break;
    }
  }

  public async create() {
    try {
      const data = {
        ...this.organizationAdminAndManagerForm,
        verifyType: ManagerTypes.client.toLowerCase(),
        org: this.org.name,
        subdomain: this.org.subdomain,
        orgEmail: this.orgAcc.email,
        lang: this.org.language,
        type: this.userType === UsersType.ADMIN ? UserTypeEnum.OrganizationAdmin : UserTypeEnum.OrganizationManager,
      };

      const response = await this._rest.post('account', data);

      if (!response) {
        throw new Error('User creation failed');
      }

      if (this.userType === UsersType.ADMIN) {
        this._adminsListService.changeAdmin(response);
      } else {
        this._managersListService.changeManager(response);
      }

      this.openSnackbar('User created successfully!');
      this.close();
    } catch (error) {
      this.openSnackbar(`User creation failed! ${error.message}`);
    }
  }

  public async update() {
    const accountId = get(this.data.manager, 'id', get(this.data.admin, 'id', ''));
    const clientId = get(this.data.manager, 'patron.id', get(this.data.admin, 'patron.id', ''));

    try {
      const [givenName, familyName = ''] = this.organizationAdminAndManagerForm.fullname.split(' ');
      this.organizationAdminAndManagerForm.givenName = givenName;
      this.organizationAdminAndManagerForm.familyName = familyName;

      const response = await this._rest.put('patron/' + clientId, {
        patron: this.organizationAdminAndManagerForm,
        accountId: accountId,
      });

      if (!response) {
        const error = get(response, 'error.message', get(response, 'message', ''));
        throw new Error(`User updated failed: ${error}`);
      }

      if (this.userType === 'admin') {
        this._adminsListService.changeAdmin(response);
      } else {
        this._managersListService.changeManager(response);
      }

      this.close();
      this.openSnackbar('User updated successfully!');
    } catch (error) {
      this.openSnackbar(`User update failed! ${error.message}`);
    }
  }

  public close() {
    const status = { status: true, updating: this.stateSave };
    this.dialogRef.close(status);
  }

  public back() {
    this.userType = '';
    Object.keys(this.organizationAdminAndManagerForm).forEach((key) => {
      this.organizationAdminAndManagerForm[key] = '';
    });
  }

  public toggleFullAccess(): void {
    this.organizationAdminAndManagerForm.allowFullAccess = !this.organizationAdminAndManagerForm.allowFullAccess;

    this.isFullAccessChecked = this.organizationAdminAndManagerForm.allowFullAccess;
  }

  public async addOrganizationImage() {
    try {
      const image: string = await this.cloudinary.handleUpload();
      this.organizationAdminAndManagerForm.image = image;
    } catch (error) {
      this.openSnackbar(error.message);
    }
  }

  public async openClientList() {
    if (this.patrons.length === 0) {
      return;
    }

    const dialog = this.dialog.open(ClientListDialogComponent, {
      width: '550px',
      height: '550px',
      panelClass: 'client-list-modalBox',
      data: {
        patrons: this.patrons,
        allowedClients: this.organizationAdminAndManagerForm.allowedClients || [],
      },
    });

    dialog.afterClosed().subscribe((allowedClients) => {
      if (allowedClients) {
        this.organizationAdminAndManagerForm.allowedClients = allowedClients;
      }
    });
  }

  private openSnackbar(message) {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
