import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ManagersListService } from './managers-list.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { UsersHelperService } from '../../../users-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { OrgAdminAndManagerCreateModalComponent } from '../../../modals/org-admin-manager-create-modal/org-admin-manager-create-modal.component';
import { firstValueFrom, Subject } from 'rxjs';
import { AdminManager, ManagerActions, Templates, UsersType } from 'src/app/shared/interfaces/Manager.interface';
import { applyFilter } from 'src/app/shared/helpers/applyFilter';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { Patron } from 'src/app/core/openapi/model/patron';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { AccountControllerService } from 'src/app/core/openapi';
import { UserTypeEnum } from '../../../components/manage-user/mock-data/user-type.enum';

interface Manager {
  name: string;
  emailVerified?: string;
  id: string;
  patron?: Patron;
}

enum SheetNames {
  MANAGERS = 'Managers',
  TEACHERS = 'Teachers',
}

@Component({
  selector: 'users-managers-list',
  templateUrl: './managers-list.component.html',
  styleUrls: ['./managers-list.component.scss'],
})
export class ManagersListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  protected template: Templates = Templates.TABLE;
  protected dataSource = new MatTableDataSource();
  public managers: Manager[];
  public isDropdownOpen: boolean = false;

  constructor(
    private _managersListService: ManagersListService,
    private _logger: LoggerService,
    private _confirm: ConfirmationService,
    private _rest: RestAPIService,
    private _usersService: UsersHelperService,
    private _dialog: MatDialog,
    private authService: AuthService,
    private excelService: ExcelService,
    private accountController: AccountControllerService,
  ) {
    this._managersListService.currentManager.subscribe(async (newManager) => {
      if (newManager) {
        await this.loadManagers();
      }
    });
  }

  async loadManagers() {
    this.template = Templates.LOADING;
    const data = await this._managersListService.getManagers({ refresh: true });
    this.managers = data;
    this.dataSource = new MatTableDataSource(data);
    this.template = Templates.TABLE;
  }

  async ngOnInit() {
    this.template = Templates.LOADING;
    const data = await this._managersListService.getManagers();
    this.dataSource = new MatTableDataSource(data);
    this.template = Templates.TABLE;
  }

  public createManager() {
    this._dialog.open(OrgAdminAndManagerCreateModalComponent, {
      data: {
        type: ManagerActions.CREATE,
        manager: {
          type: UsersType.MANAGER,
        },
      },
    });
  }

  public editManager(manager: AdminManager) {
    this._dialog.open(OrgAdminAndManagerCreateModalComponent, {
      data: {
        type: ManagerActions.UPDATE,
        manager: manager,
      },
    });
  }
  public resendEmailVerification(manager: AdminManager) {
    this._managersListService.resendManagerVerificationEmail(manager);
  }
  public deleteManager(manager: AdminManager) {
    this._confirm
      .createConfirmation('Warning', 'Are you sure you want to delete this manager?', 'Yes', 'No')
      .then(async () => {
        try {
          await this._rest.delete('patron/' + manager.patron.id, { msg: 'Could not delete manager.' });

          const data = await this._managersListService.getManagers({ refresh: true });
          this.dataSource = new MatTableDataSource(data);
        } catch (err) {
          this._logger.error(err);
        }
      });
  }

  public applyFilter(event: Event): void {
    this.dataSource = applyFilter(event, this.dataSource);
  }

  getEmailVerifiedTooltip(isEmailVerified: boolean | undefined): string {
    return this._usersService.getEmailVerifiedTooltip(isEmailVerified);
  }

  public isDataSourceEmpty(): boolean {
    return this.dataSource.data.length === 0;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public triggerFileInput() {
    const fileInput = document.getElementById('fileInputManager') as HTMLInputElement;
    fileInput.click();
  }

  public closeDropdown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const dropdown = document.querySelector('.dropdown-container');

    if (dropdown && !dropdown.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

  private async getSheetName() {
    const user = await this.authService.getUser();
    const org = get(user, 'organization', {});
    const isSchool = get(org, 'isSchool', false);

    return !isSchool ? SheetNames.MANAGERS : SheetNames.TEACHERS;
  }

  public async downloadFileExampleManagers() {
    try {
      const sheetName = await this.getSheetName();

      const managers = [
        {
          fullname: 'Liam Smith',
          email: 'liamsmith@example.com',
        },
      ];

      this.excelService.downloadFileExample(managers, sheetName.toLowerCase(), `Import${sheetName}FileExample`);
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error creating sample spreadsheet: ' + error.message,
      });
    }
    this.toggleDropdown();
  }

  public async importManagers(e: Event) {
    let sheetName = '';
    try {
      const user = await this.authService.getUser();
      const org = get(user, 'organization', {});
      const isSchool = get(org, 'isSchool', false);
      sheetName = !isSchool ? SheetNames.MANAGERS : SheetNames.TEACHERS;

      const target = e.target as HTMLInputElement;
      const files = target.files;

      if (!files?.length) {
        this._dialog.open(AlertDialogComponent, {
          width: '400px',
          data: 'No file selected. Please select a file to import.',
        });
        return false;
      }

      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await firstValueFrom(
        this.accountController.accountControllerAccountImport(UserTypeEnum.OrganizationManager, org.id, file),
      );

      if (response?.status === 200) {
        this.toggleDropdown();
        this._dialog.open(AlertDialogComponent, {
          width: '600px',
          data: response.message,
        });
        this.template = Templates.LOADING;
        await this.loadManagers();
        this.template = Templates.TABLE;
      }
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: `Error importing ${sheetName}: ` + error.message,
      });
      this.toggleDropdown();
    }
  }

  public async exportManagers() {
    if (!this.managers?.length) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'No managers to export.',
      });
      return;
    }
    try {
      const sheetName = await this.getSheetName();

      const managersData = this.managers.map((manager) => {
        return {
          fullname: `${get(manager.patron, 'givenName', '')}  ${get(manager.patron, 'familyName', '')}`,
          givenName: get(manager.patron, 'givenName', ''),
          familyName: get(manager.patron, 'familyName', ''),
          email: get(manager, 'email', ''),
          allowFullAccess: get(manager.patron, 'allowFullAccess', false),
          status: get(manager, 'status', ''),
        };
      });

      this.excelService.exportData(managersData, sheetName.toLowerCase(), sheetName);

      this.toggleDropdown();
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error exporting managers: ' + error.message,
      });
    }
  }
}
