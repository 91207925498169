import { get } from 'lodash';
import { Router } from '@angular/router';
import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { ThemeService } from 'src/app/services/themes/themes.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { StudentHelperService } from 'src/app/services/student/student-helper.service';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SchoolStudent, StudentControllerService } from 'src/app/core/openapi';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { PortalConfig } from 'src/app/services/auth/auth-consts/auth-consts';

@Injectable({
  providedIn: 'root',
})
export class StudentsListService {
  private studentSource = new BehaviorSubject<Student>(null);
  public currentStudent = this.studentSource.asObservable();
  public students: Student[];
  public currentClient: string;
  public schoolId: string;

  public studentAssociated = new EventEmitter<void>();
  public refreshSchoolList = new EventEmitter<void>();
  public refreshStudents = new EventEmitter<void>();
  public activeConfig: PortalConfig = PortalConfig.DEFAULT;

  constructor(
    private _logger: LoggerService,
    private _rest: RestAPIService,
    private _router: Router,
    private _themeService: ThemeService,
    private _dialog: MatDialog,
    private studentHelper: StudentHelperService,
    private auth: AuthService,
    private studentController: StudentControllerService,
    private excelService: ExcelService,
  ) {}

  public changeStudent(student: Student) {
    this.studentSource.next(student);
  }

  public async showStudents(clientId: string, options?: { refresh: boolean }): Promise<Student[]> {
    // Update if no current data or forced refresh
    if (options?.refresh || !this.students) {
      // Save selected client id for other methods to use
      this.currentClient = clientId;

      try {
        // Get students
        const response = await this._rest.get(`/patron/${clientId}/students`);

        if (!response || !response.students) {
          return [];
        }

        // Transform student data for template to consume
        this.students = response.students.map((student: Student) => {
          return {
            ...student,
            name: this._transformStudentName(student),
            age: this._transformStudentAge(student),
          };
        });
      } catch (error) {
        throw new Error(error.message);
      }
    }
    return this.students;
  }

  public async showSchoolStudents(refresh: boolean) {
    await this.auth.getUser(refresh);
    const user = this.auth.getOrgAcc();
    const organization = user.organization;
    this.schoolId = get(organization, 'id', '');

    const studentList = await firstValueFrom(
      this.studentController.studentControllerGetSchoolStudents(organization.id),
    );
    return studentList;
  }

  public async addStudentProgram(student: Student): Promise<void> {
    try {
      const response = await this._rest.get('token/self', { msg: 'Could not get token.' });
      if (!response.tokens || response.tokens.length === 0) {
        this._router.navigate(['programs-pricing']);
        return;
      }
      const availableToken = response.tokens.find((t) => !t.studentId && t.paymentConfirmed);
      if (!availableToken) {
        this._router.navigate(['programs-pricing']);
      } else {
        const allowHomeAccess = get(student, 'enableHomeAccess', false);

        await this._rest.put(`token/${availableToken.id}/student/${student.id}/allowHomeAccess/${allowHomeAccess}`, {
          msg: 'Could associate the token to this student, please try again.',
        });
        const updatedStudent = await this._getUpdatedStudent(student.id);

        if (updatedStudent) {
          student.tokens = updatedStudent.tokens;
          if (this.studentAssociated.observers.length > 0) {
            this.studentAssociated.emit();
          }
        }
      }
    } catch (err) {
      this._logger.error(err);
    }
  }

  public async removeStudentProgram(student: Student): Promise<void> {
    try {
      const token = student.tokens[0];
      await this._rest.put('token/' + token.id + '/disassossiate', {
        msg: 'Could not put student token.',
      });
      const updatedStudent = await this._getUpdatedStudent(student.id);

      if (updatedStudent) {
        student.tokens = updatedStudent.tokens;
        if (this.studentAssociated.observers.length > 0) {
          this.studentAssociated.emit();
        }
      }
    } catch (err) {
      this._logger.error(err);
    }
  }

  public async editStudent(student: Student): Promise<void> {
    const themeLabel = await this._getStudentThemeLabel(student);

    const studentFormControlValues = this._getStudentFormControlValues(student);

    const queryParams = await this.studentHelper.getProfileQueryParams(studentFormControlValues as Student, themeLabel);

    this._router.navigate(['/students/profile/' + this.currentClient], {
      queryParams,
    });
  }

  public accessNeuralign(student: any): void {
    if (this.doesStudentHaveTokens(student)) {
      this._router.navigate(['/students/programs/' + this.getStudentName(student) + '/' + student.id]);
    } else {
      this._dialog.open(AlertDialogComponent, {
        data: 'This student dont have associated tokens',
        panelClass: 'modal-border',
        width: '400px',
        height: '230px',
      });
    }
  }

  public async archiveStudent(student: Student | SchoolStudent): Promise<void> {
    try {
      await this._rest.put('student/archive/' + student.id, {}, { msg: 'Could not put student/archive.' });
    } catch (err) {
      this._logger.error(err);
    }
  }

  public doesStudentHaveTokens(student: Student): boolean {
    return student.tokens && student.tokens.length > 0;
  }

  public doesStudentHaveProgress(student: Student): boolean {
    return student.progress && student.progress.length > 0;
  }

  private async _getUpdatedStudent(id: string): Promise<any> {
    try {
      const response = await this._rest.get(`student/${id}`);

      const student = get(response, 'student', {});
      const tokens = get(response, 'tokens', []);

      return { student, tokens };
    } catch (err) {
      this._logger.error(err);
    }
  }

  private async _getStudentThemeLabel(student: Student): Promise<any> {
    const themes = await this._themeService.getEnabledThemes();

    let theme = themes.find((t) => t.id === student.theme);

    if (!theme) {
      theme = this._themeService.getClassicTheme();
    }

    return theme.label.en_ca;
  }

  private _getStudentFormControlValues(student: Student): Record<string, any> {
    // When we go to edit a student we have to pass in only some of the student's
    // properties so that the StudentProfileComponent can load its form.
    return {
      id: student.id,
      familyName: student.familyName,
      givenName: student.givenName,
      fullname: student.fullname,
      nickname: student.nickname,
      birthdate: student.birthdate,
      image: student.image,
      language: student.language,
      createdBy: student.createdBy,
      alertOnSessionEnd: student.alertOnSessionEnd,
      theme: student.theme,
      patronId: student.patronId,
      tokens: student.tokens,
      enableHomeAccess: student.enableHomeAccess,
    };
  }

  private _transformStudentName(student: Student): string {
    if (student?.fullname) {
      return student.fullname;
    } else {
      return student.givenName + ' ' + student.familyName;
    }
  }

  private _transformStudentAge(student: Student): string {
    if (!student.birthdate) {
      return '--';
    }

    const ageDifMs = Date.now() - new Date(student.birthdate).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970).toString();
  }

  private getStudentName(student: Student): string {
    if (this._isStudentNickNameNotEmpty(student)) {
      return student.nickname;
    } else {
      return student.givenName;
    }
  }

  private _isStudentNickNameNotEmpty(student: Student): boolean {
    return student.nickname && student.nickname.trim() !== '';
  }

  public downloadStudentFile(isSchool?: boolean): void {
    try {
      const students = [
        {
          ...(isSchool ? { email: 'liamsmith@example.com' } : {}),
          givenName: 'Liam Smith',
          familyName: 'Smith',
          nickname: 'Liam',
          language: 'en_ca',
          birthdate: '04/2000',
          ...(isSchool ? { grade: '3' } : {}),
        },
      ];
      this.excelService.downloadFileExample(students, 'students', 'ImportStudentsFileExample');
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error creating sample spreadsheet: ' + error.message,
      });
    }
  }

  public async importStudentsForFile(e: Event): Promise<boolean> {
    try {
      const user = this.auth.getOrgAcc();
      const organization = user.organization;
      const orgId = !this.isSchoolConfig() ? get(organization, 'id', '') : this.schoolId;

      const target = e.target as HTMLInputElement;
      const files = target.files;

      if (!files?.length) {
        this._dialog.open(AlertDialogComponent, {
          width: '400px',
          data: 'No file selected. Please select a file to import.',
        });
        return false;
      }

      const file = files[0];

      const formData = new FormData();
      formData.append('file', file);

      const response = await firstValueFrom(
        this.studentController.studentControllerStudentImport('student', orgId, file),
      );

      if (response?.status === 200) {
        this._dialog.open(AlertDialogComponent, {
          width: '600px',
          data: response.message,
        });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this._logger.error(error);
    }
  }

  public exportStudents(students) {
    if (!students?.length) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'No students to export',
      });
      return;
    }

    try {
      const studentsData = students.map((student) => {
        return {
          fullname: student.fullname,
          ...(this.isSchoolConfig() ? { email: student.email } : {}),
          givenName: student.givenName,
          familyName: student.familyName,
          nickname: student.nickname,
          birthdate: student.birthdate,
          age: this.getStudentAge(student.birthdate),
          ...(this.isSchoolConfig() ? { school: student.school } : {}),
        };
      });

      this.excelService.exportData(studentsData, 'students', 'ExportStudents');
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error exporting students: ' + error.message,
      });
    }
  }

  public getStudentAge(birthMonthYear: string): string {
    if (!birthMonthYear) {
      return '--';
    }

    if (birthMonthYear.includes('-')) {
      birthMonthYear = birthMonthYear.replace('-', '/');
    }

    const matchMonthFirst = birthMonthYear.match(/^(\d{1,2})\/(\d{4})$/);
    const matchYearFirst = birthMonthYear.match(/^(\d{4})\/(\d{1,2})$/);

    let birthMonth: number;
    let birthYear: number;

    if (matchMonthFirst) {
      birthMonth = Number(matchMonthFirst[1]);
      birthYear = Number(matchMonthFirst[2]);
    } else if (matchYearFirst) {
      birthMonth = Number(matchYearFirst[2]);
      birthYear = Number(matchYearFirst[1]);
    } else {
      return '--';
    }

    if (birthMonth < 1 || birthMonth > 12) {
      return '--';
    }

    const today = new Date();
    let age = today.getFullYear() - birthYear;

    if (today.getMonth() + 1 < birthMonth) {
      age--;
    }

    return age.toString();
  }

  public isSchoolConfig() {
    const config = this.auth.activeConfig;

    return config === PortalConfig.SCHOOL;
  }
}
