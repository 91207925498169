import { Injectable } from '@angular/core';
import { ageBasedLevel, progressTypes, studentLevelMap } from 'src/app/shared/consts/global-constants';
import { isString, toNumber } from 'lodash';
import { Token } from 'src/app/shared/models';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { ASSESSMENT, OverwriteTrigger, StudentLevel } from '../consts/program-game-consts';
import { get } from 'lodash';
import { Theme } from '../../configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { Category } from '../../configuration-pages/interfaces/global-config.interfaces';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Progress } from 'src/app/core/openapi';

@Injectable({
  providedIn: 'root',
})
export class StudentProgramInfoHelperService {
  constructor(public rest: RestAPIService) {}

  public studentTheme;
  public maxLevel = 4;

  public returnStudentLevel = {
    classic(score, studentAge) {
      let level;

      if (studentAge <= 7) {
        level = score > 23 ? 1 : 0;
      } else if (studentAge === 8) {
        level = 1;
      } else if (studentAge === 9) {
        level = score < 20 ? 1 : 2;
      } else if (studentAge === 10 || studentAge === 11) {
        level = 2;
      } else if (studentAge === 12 || studentAge === 13) {
        level = score < 30 ? 2 : 3;
      } else if (studentAge >= 14 && studentAge <= 17) {
        level = 3;
      } else if (studentAge >= 18) {
        level = 4;
      }

      return level;
    },

    cdh(score, studentAge) {
      let level;

      if (studentAge <= 8) {
        level = 1;
      } else if (studentAge === 9) {
        level = score < 20 ? 1 : 2;
      } else if (studentAge === 10 || studentAge === 11) {
        level = 2;
      } else if (studentAge === 12 || studentAge === 13) {
        level = score < 30 ? 2 : 3;
      } else if (studentAge >= 14) {
        level = 3;
      }

      return level;
    },

    ageBasedLevel(studentAge) {
      let level;

      if (studentAge < 8) {
        level = 0;
      } else if (studentAge === 8 || studentAge === 9) {
        level = 1;
      } else if (studentAge > 9 && studentAge <= 12) {
        level = 2;
      } else if (studentAge >= 13 && studentAge <= 15) {
        level = 3;
      } else if (studentAge >= 16) {
        level = 4;
      }

      return level;
    },
  };

  public returnLevelMap(studentLevel) {
    let levelMap;
    const convertedStudentLevel = isString(studentLevel) ? toNumber(studentLevel) : studentLevel;

    if (convertedStudentLevel === 0) {
      levelMap = studentLevelMap.LEVEL_JR;
    } else if (convertedStudentLevel === 1) {
      levelMap = studentLevelMap.LEVEL_P1;
    } else if (convertedStudentLevel === 2) {
      levelMap = studentLevelMap.LEVEL_P2;
    } else if (convertedStudentLevel === 3) {
      levelMap = studentLevelMap.LEVEL_P3;
    } else if (convertedStudentLevel === 4) {
      levelMap = studentLevelMap.LEVEL_P4;
    } else {
      levelMap = convertedStudentLevel;
    }

    return levelMap;
  }

  public themeHasAssessment(themeInfo): boolean {
    if (!themeInfo) {
      return true;
    } else {
      this.studentTheme = themeInfo;
      const assessmentCategory = themeInfo.categories.find(
        (c) => c.name.toLowerCase() === progressTypes.ASSESSMENT.toLowerCase(),
      );

      return assessmentCategory;
    }
  }

  public studentHaveAssessmentProgress(progress): boolean {
    const assessment = progress.filter(
      (p) =>
        p.tag === progressTypes.ASSESSMENT.toLowerCase() &&
        typeof p.metadata?.level !== 'undefined' &&
        p.metadata?.level !== null,
    );

    return assessment.length > 0;
  }

  public getStudentAge(birthdate: Date) {
    // Empty birthdates can cause a crash on the program page
    // TODO check if the import feature is adding the birthdate as a mandatory variable

    if (!birthdate) {
      return 0;
    }

    const ageDifMs = Date.now() - new Date(birthdate).getTime();
    const ageDate = new Date(ageDifMs);
    const studentAge = Math.abs(ageDate.getUTCFullYear() - 1970);

    return studentAge;
  }

  public getAssessmentLevel(progress: Progress[]) {
    const assessmentProgress = progress.filter(
      (p) => p.tag === ASSESSMENT && typeof p.metadata?.level !== 'undefined' && p.metadata.level !== null,
    );

    if (assessmentProgress.length === 0) {
      return null;
    }

    const as = assessmentProgress.reduce((oldest, current) => {
      return new Date(current.creationTime) < new Date(oldest.creationTime) ? current : oldest;
    });

    if (as) {
      return as.metadata.level;
    }
    return null;
  }

  public getCategoryLevel(progress: Progress[], category: Category) {
    const assessmentProgress = progress.filter(
      (p) => p.tag === ASSESSMENT && typeof p.metadata?.level !== 'undefined' && p.metadata.level !== null,
    );

    const lastIndex = assessmentProgress.length - 1;
    const lastAssessment = assessmentProgress[lastIndex];
    const categoryLevels = get(lastAssessment, 'metadata.categoryLevels', undefined);

    if (categoryLevels && category) {
      const categoryLevelsList = lastAssessment.metadata.categoryLevels || [];
      const categoryLevel = categoryLevelsList.find(
        (l) => l.categoryId === category.id || l.categoryName === category.name,
      );

      return categoryLevel ? categoryLevel.assessmentLevel : lastAssessment.metadata.level;
    } else {
      return this.getAssessmentLevel(progress);
    }
  }

  public getLevelBasedOnAge(student: Student, theme: Theme) {
    const studentAge = this.getStudentAge(student.birthdate);
    const equation = this.returnStudentLevel[ageBasedLevel];

    let level = equation(studentAge);

    if (!level) {
      level = 0;
    }

    const selectedLanguage = theme.languages.find((l) => l.languageCode === student.language);
    const selectedLevel = selectedLanguage.enabledLevels.find((l) => l.assessmentLevel === level);

    if (!selectedLevel.enabled) {
      const enabledLevels = selectedLanguage.enabledLevels.filter((l) => l.enabled);
      const assessmentLevels = enabledLevels.map((l) => l.assessmentLevel);

      const closestLevel = assessmentLevels.reduce((a, b) => {
        return Math.abs(b - level) < Math.abs(a - level) ? b : a;
      });

      level = closestLevel;
    }

    return level;
  }

  public async checkAssessment(studentProgress: Progress[], student: Student, theme: Theme) {
    if (!this.studentHaveAssessmentProgress(studentProgress) && !this.themeHasAssessment(theme)) {
      const level = this.getLevelBasedOnAge(student, theme);

      // save the progress so we dont need to calculate the student level again
      const progress = {
        session: 1,
        metadata: {
          level: Number(level),
          score: 0,
        },
        studentId: student.id,
        tag: progressTypes.ASSESSMENT,
      };

      await this.rest.post('/progress/overwritenAssessment/overwriteType/' + OverwriteTrigger.PROGRAM, progress);
    }
  }

  public getStudentLevel(student: Student, theme: Theme, progress: Progress[], category?: Category) {
    let level = 0;

    const asssociatedToken = student.tokens[0];

    if (!this.studentHaveAssessmentProgress(progress)) {
      const studentAge = this.getStudentAge(student.birthdate);
      const equation = this.returnStudentLevel[ageBasedLevel];

      level = equation(studentAge);

      if (!level) {
        level = 0;
      }

      // if the theme dont have assessment we need to check if the level based on age is enabled on the theme

      const selectedLanguage = theme.languages.find((l) => l.languageCode === student.language);
      const selectedLevel = selectedLanguage.enabledLevels.find((l) => l.assessmentLevel === level);

      if (!selectedLevel.enabled) {
        const enabledLevels = selectedLanguage.enabledLevels.filter((l) => l.enabled);
        const assessmentLevels = enabledLevels.map((l) => l.assessmentLevel);

        // if the student level isnt enabled we use the closest option

        const closestLevel = assessmentLevels.reduce((a, b) => {
          return Math.abs(b - level) < Math.abs(a - level) ? b : a;
        });

        level = closestLevel;
      }
    } else if (category) {
      level = this.getCategoryLevel(progress, category);
    } else {
      level = this.getAssessmentLevel(progress);
    }

    if (get(asssociatedToken, 'allowProgressRerun', false)) {
      level = this.getLevelForProgressRerun(level, asssociatedToken, progress);
    }

    return level;
  }

  public getLevelForProgressRerun(level: number, token: Token, progress: Progress[]): number {
    const rerun = token.progressRun || 0;

    if (level === StudentLevel.P4 && rerun === 0) {
      return StudentLevel.P3;
    } else {
      return level;
    }
  }

  public async IncreaseAssessmentLevel(studentProgress: Progress[], student: Student, programId: string, theme: Theme) {
    const assessmentProgressList = studentProgress.filter((p) => p.tag === ASSESSMENT);

    if (assessmentProgressList.length === 0) {
      const progress = {
        session: 1,
        metadata: {
          level:
            this.getStudentLevel(student, theme, studentProgress) + 1 < this.maxLevel
              ? this.getStudentLevel(student, theme, studentProgress) + 1
              : this.maxLevel,
          score: 0,
        },
        studentId: student.id,
        programId: programId,
        tag: progressTypes.ASSESSMENT,
      };

      await this.rest.post('/progress/overwritenAssessment/overwriteType/' + OverwriteTrigger.RERUN, progress);
    } else {
      for (const assessment of assessmentProgressList) {
        const level =
          Number(assessment.metadata.level) + 1 < this.maxLevel ? Number(assessment.metadata.level) + 1 : this.maxLevel;
        assessment.metadata.level = level;

        await this.rest.put(
          '/progress/overwriteAssessment/' + assessment.id + /overwriteType/ + OverwriteTrigger.RERUN,
          assessment,
        );
      }
    }
  }

  public fixProgressWithNullLevel(student: Student) {
    if (!this.studentHaveAssessmentProgress(student.progress)) {
      const studentAge = this.getStudentAge(student.birthdate);
      const equation = this.returnStudentLevel[ageBasedLevel];
      const level = equation(studentAge);

      return this.returnLevelMap(level);
    } else {
      const level = this.getAssessmentLevel(student.progress);
      return this.returnLevelMap(level);
    }
  }
}
