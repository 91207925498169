import { Student } from 'src/app/pages/students/interfaces/student.interface';

export function validateStudentImportData(data: Student[]) {
  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    const student = data[rowIndex];

    const giveNameError = validateGivenName(student.givenName, rowIndex);
    if (giveNameError) return giveNameError;

    const languageError = validateLanguage(student.language, rowIndex);
    if (languageError) return languageError;
  }

  return null;
}

function validateGivenName(givenName: string | undefined, rowIndex: number) {
  if (!givenName) {
    return { error: `Given name is required at row ${rowIndex + 1}` };
  }
  return null;
}

function validateLanguage(language: string | undefined, rowIndex: number) {
  if (language && language !== 'en_ca' && language !== 'fr_ca') {
    return {
      error: `The language is invalid, please update for en_ca or fr_ca at row ${rowIndex + 1}. Received: ${language}`,
    };
  }
  return null;
}
