import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OrganizationsListService } from './organizations-list.service';
import { UsersHelperService } from '../../../users-helper.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { IOutsiderOrg, Token } from 'src/app/shared/interfaces';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { TokenAmountModalComponent } from 'src/app/shared/dialogs/add-token-amount/add-token-amount.dialog';
import { MatDialog } from '@angular/material/dialog';
import { MainLayoutComponent } from 'src/app/shared/layouts/main-layout/main-layout.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { get } from 'lodash';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { OrganizationsCreateModalComponent } from '../../../modals/organizations-create-modal/organizations-create-modal.component';
import { Subject } from 'rxjs';
import { ManagerActions, Templates } from 'src/app/shared/interfaces/Manager.interface';
import { applyFilter } from 'src/app/shared/helpers/applyFilter';
import { PortalConfig } from 'src/app/services/auth/auth-consts/auth-consts';
import { Organization } from 'src/app/core/openapi';
import { ExcelService } from 'src/app/services/excel/excel.service';

@Component({
  selector: 'users-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss'],
})
export class OrganizationsListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @ViewChild(MainLayoutComponent) mainLayoutComponent: MainLayoutComponent;
  public isLoadingExport = false;
  template: Templates = Templates.TABLE;
  dataSource;

  public readonly spinner: IconDefinition = faSpinner;
  public refreshCredits = new EventEmitter<void>();

  public isSchoolSetup = false;
  public isDropdownOpen: boolean = false;

  constructor(
    private _logger: LoggerService,
    private _organizationsListService: OrganizationsListService,
    private _usersService: UsersHelperService,
    private _rest: RestAPIService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _confirm: ConfirmationService,
    private _dialog: MatDialog,
    private _auth: AuthService,
    private _usersHelperService: UsersHelperService,
    private excel: ExcelService,
  ) {
    this._organizationsListService.currentOrganization.subscribe(async (newOrganization) => {
      if (newOrganization) {
        await this.loadOrganizations();
      }
    });
  }
  async ngOnInit(): Promise<void> {
    this.template = Templates.LOADING;
    this.isSchoolSetup = this._auth.activeConfig === PortalConfig.SCHOOL;
    await this.loadOrganizations();
  }

  async loadOrganizations() {
    this.template = Templates.LOADING;
    const data = await this._organizationsListService.getOrganizations({ refresh: true });
    this.dataSource = new MatTableDataSource(data);
    this.template = Templates.TABLE;
  }

  getEmailVerifiedTooltip(isEmailVerified: boolean | undefined): string {
    return this._usersService.getEmailVerifiedTooltip(isEmailVerified);
  }

  public applyFilter(event: Event): void {
    this.dataSource = applyFilter(event, this.dataSource);
  }

  public async retrieveCredits(user: IOutsiderOrg): Promise<void> {
    if (get(user, 'credits') < 1) {
      this._confirm.createConfirmation('Warning', 'This organization dont have credits avaliable.', 'Ok', undefined);
    }

    const name = get(user, 'organization.name', '');

    const dialogData = {
      width: '350px',
      data: {
        amount: 1,
        warningMessage: 'How many credits do you want to retrieve from' + ' ' + name + '?',
      },
    };

    const dialog = this._dialog.open(TokenAmountModalComponent, dialogData);

    dialog.afterClosed().subscribe(async (data: { amount: number; organizationName: string }) => {
      try {
        if (data) {
          if (get(data, 'amount', 0) > 0) {
            user.credits = undefined;
            const updatedTokensAmount = await this._rest.put(
              '/organization/outsider/' + user.id + '/retrieveToken/' + data.amount,
              {},
            );
            user.credits = updatedTokensAmount;
            this._organizationsListService.refreshOrgCredits();

            this._snackBar.open('Tokens retrieved!', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        }
      } catch (error) {
        this._snackBar.open(error.message, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  public async addCredits(org: IOutsiderOrg): Promise<void> {
    // Dialogs --------------------------------------
    const showNotEnoughCreditsDialog = () => {
      this._confirm.createConfirmation('Warning', 'Not enough credits available.', 'Ok', undefined);
      return;
    };
    const showAddAtLeastOneCreditDialog = () => {
      this._confirm.createConfirmation('Warning', 'Please add at least one credit.', 'Ok', undefined);
      return;
    };

    const dialogData = {
      width: '350px',
      data: {
        amount: 1,
        organizationName: org.name,
        warningMessage: 'How many credits do you want to add to' + ' ' + org.name + '?',
      },
    };

    // Process ---------------------------------------

    // Open token dialog
    const dialog = this._dialog.open(TokenAmountModalComponent, dialogData);

    // Process add credits request
    dialog.afterClosed().subscribe(async (data: { amount: number; organizationName: string }) => {
      if (data) {
        // Guard against user spending less than one credit
        if (get(data, 'amount', 0) < 1) {
          return showAddAtLeastOneCreditDialog();
        }

        // Get tokens
        const tokenResponse = await this._rest.get('token/self', { msg: 'Could not get token.' });
        const tokens = tokenResponse.tokens as Token[];

        // Get available tokens by filtering out unusable tokens
        const avaliableTokens = tokens.filter(this.removeTokensInUse).filter(this.removeUnconfirmedPaymentTokens);

        // Guard not having enough tokens
        if (avaliableTokens.length < data.amount) {
          return showNotEnoughCreditsDialog();
        }

        // Warning confirmations
        if (!org.isReseller || !org.isTrusted) {
          if (avaliableTokens.length < 1) {
            return showNotEnoughCreditsDialog();
          }
        } else if (org.isTrusted) {
          if (avaliableTokens.length < org.maximumTokenDebit) {
            return showNotEnoughCreditsDialog();
          }
        }

        // Construct dialog message
        const warningMessage = `Are you sure you want to grant ${data.amount} ${this.creditPlural(data.amount)} to ${
          org.name
        }?`;

        // Create main confirmation
        this._confirm.createConfirmation('Warning', warningMessage, 'Yes', 'No').then(async () => {
          try {
            // Get organization and user
            const organization = await this._usersHelperService.getCurrentOrganization();
            const user = this._auth.user;
            const balance = org.credits + data.amount;
            org.credits = undefined;
            // Transfer tokens
            await this._rest.put(
              'token/transfer',
              {
                outsiderAccId: { accountId: org.id },
                amount: data.amount,
                accountId: user.organization ? user.id : organization.accountId,
                tokenTransfer: true,
              },
              { msg: 'Could not put token' },
            );

            // Notify success
            this._snackBar.open('Credits granted!', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });

            // Refresh tokens
            org.credits = balance;
            this._organizationsListService.refreshOrgCredits();

            // Handle errors
          } catch (err) {
            this._logger.error(err);
          }
        });
      }
    });
  }

  public removeTokensInUse(token: Token) {
    return !token.studentId;
  }

  public removeUnconfirmedPaymentTokens(token: Token) {
    return !token.paymentConfirmend;
  }

  public creditPlural(credits: number) {
    return credits === 1 ? 'credit' : 'credits';
  }

  public createOrganization() {
    this._dialog.open(OrganizationsCreateModalComponent, {
      data: {
        type: ManagerActions.CREATE,
      },
    });
  }

  public editOrganization(org: Organization) {
    this._dialog.open(OrganizationsCreateModalComponent, {
      data: {
        type: ManagerActions.UPDATE,
        organization: org,
      },
    });
  }

  public resendVerificationEmail(org: Organization) {
    this._rest
      .get('account/email/verify/resend/' + org.id, {
        msg: 'Could not get account/email/resend/:clientAccountId.',
      })
      .then(() => {
        this._snackBar.open(`Verification email has been sent.`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      })
      .catch(() => {
        this._snackBar.open('E-mail not found', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  adjustCustomPrice(org: Organization) {
    this._router.navigate(['/admin-pricing'], { queryParams: { id: org.id, name: org.name } });
  }

  deleteOrg(outsider: Organization) {
    const warningMessage =
      'Are you sure you want to delete this organization? All students associated with this client will be deleted!';
    this._confirm.createConfirmation('Warning', warningMessage, 'Yes', 'No').then(async () => {
      try {
        // Once the delete on Auth0 is fixed it add the /:tokenId to the path
        await this._rest
          .delete('organization/outsider/' + outsider.id + '/' + outsider['tokenId'], {
            msg: 'Could not delete organization.',
          })
          .then(() => {
            this._snackBar.open(`Organization deleted.`, 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          })
          .then(async () => {
            this.template = Templates.LOADING;
            const data = await this._organizationsListService.getOrganizations({ refresh: true });
            this.dataSource = new MatTableDataSource(data);
            this.template = Templates.TABLE;
          });
      } catch (err) {
        this._logger.error(err);
      }
    });
  }

  public isDataSourceEmpty(): boolean {
    return get(this.dataSource, 'data.length', []).length === 0;
  }

  async exportOrganizations() {
    this.isLoadingExport = true;

    const response = await this._rest.get(`/organization/self/outsiders`);
    const outsiders = response?.outsiders ?? [];

    if (outsiders.length === 0) {
      this.isLoadingExport = false;
      this._snackBar.open(`There are no organizations to be exported`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.toggleDropdown();
      return;
    }

    const formattedOutsiders = outsiders.map((outsider) => ({
      name: get(outsider, 'organization.name', ''),
      email: get(outsider, 'organization.email', ''),
      'Postal/Zip code': get(outsider, 'organization.address.postalCode', ''),
    }));

    const reportName = `Organizations_${new Date().toLocaleDateString()}`;
    this.excel.exportAsExcelFile(formattedOutsiders, reportName);
    this.isLoadingExport = false;
    this.toggleDropdown();
  }

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
