<app-main-layout
  [breadcrumbs]="[
    { name: 'Home', url: '/' },
    { name: 'Admin', url: '/admin' },
    { name: 'Dashboard' }
  ]"
>
  <div
    class="inner-content"
    *ngIf="clients && organizations"
  >
    <h2>Users</h2>
    <div
      class="filterBar col-12 d-flex justify-content-between align-items-center"
    >
      <div class="userType col-12 d-flex">
        <div class="col-6 px-0 d-flex justify-content-between">
          <div class="col-4 mx-1">
            <div
              class="type clients"
              (click)="selectedUserType = 'Clients'; updateFilteredUsers()"
              [ngClass]="{ selected: selectedUserType === 'Clients' }"
            >
              Clients
            </div>
          </div>
          <div class="col-4 mx-1">
            <div
              class="type orgs"
              (click)="
                selectedUserType = 'Organizations'; updateFilteredUsers()
              "
              [ngClass]="{ selected: selectedUserType === 'Organizations' }"
            >
              Organizations
            </div>
          </div>
          <div class="col-4 mx-1">
            <div
              class="type clients"
              (click)="selectedUserType = 'Students'; updateFilteredUsers()"
              [ngClass]="{ selected: selectedUserType === 'Students' }"
            >
              Students
            </div>
          </div>
        </div>

        <div class="col-6 px-2 filter-col">
          <mat-form-field class="inputs">
            <mat-label>Search</mat-label>
            <input
              matInput
              placeholder="Search"
              [(ngModel)]="filterByName"
              (keydown)="updateFilteredUsers()"
            />
          </mat-form-field>
          <fa-icon
            class="pointer"
            [ngClass]="{
              org: selectedUserType === 'Organizations',
              client: selectedUserType === 'Clients'
            }"
            *ngIf="filterByName"
            (click)="filterByName = ''; updateFilteredUsers()"
            [icon]="closeIcon"
          ></fa-icon>
          <mat-form-field class="inputs">
            <mat-label>Order by</mat-label>
            <mat-select
              [(value)]="selectedOrderBy"
              (selectionChange)="updateOrder()"
            >
              <mat-option
                *ngFor="let order of orderByList"
                [value]="order"
              >
                {{ order }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="inputs">
            <mat-label>Direction</mat-label>
            <mat-select
              [(value)]="selectedOrderByDirection"
              (selectionChange)="updateOrder()"
            >
              <mat-option
                *ngFor="let order of orderByDirection"
                [value]="order"
              >
                {{ order }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div
      class="userList col-12"
      *ngIf="
        selectedUserType === 'Clients' || selectedUserType === 'Organizations'
      "
    >
      <div
        class="userRow col-12"
        *ngFor="let user of filteredItens"
        [ngClass]="{ client: user.patron, org: user.organization }"
      >
        <div class="d-flex p-4">
          <div class="col-4">
            {{ user.patron?.givenName || user.organization?.name }}
            {{ user.patron?.familyName }}
          </div>
          <div class="col-4">
            <span>
              {{ user.belongsTo?.name || (user.patron ? 'B2C' : '') }}
            </span>
            <fa-icon
              class="pointer"
              *ngIf="user.belongsTo"
              [icon]="searchIcon"
              (click)="selectBelongsTo(user.belongsTo.id)"
            >
            </fa-icon>
          </div>
          <div class="openClose offset-3 col-1">
            <fa-icon
              *ngIf="selectedUser !== user.id"
              (click)="selectUser(user.id)"
              [icon]="plusLine"
            ></fa-icon>
            <fa-icon
              *ngIf="selectedUser === user.id"
              (click)="deselectUser()"
              [icon]="minusLine"
            ></fa-icon>
          </div>
        </div>
        <div
          class="col-12 mx-0 px-4"
          *ngIf="selectedUser === user.id && currentTokens"
        >
          <hr />
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <div class="mx-1"><b>Email:</b></div>
              <div class="mx-1">
                {{ user.patron?.email || user.organization?.email || '--' }}
              </div>
            </div>
            <div *ngIf="user.organization">
              <fa-icon
                class="pointer mr-3 mx-2"
                [icon]="userEdit"
                matTooltipPosition="above"
                [matTooltip]="
                  user.organization?.publicProfile
                    ? 'Edit the organization public profile'
                    : 'Create the organization public profile'
                "
                (click)="editPublicProfile(user)"
              >
              </fa-icon>

              <fa-icon
                [ngClass]="{ disabledIcon: !user.organization?.publicProfile }"
                [matTooltip]="
                  user.organization?.publicProfile
                    ? 'Preview the organization public profile'
                    : 'This organization dont have a saved public profile'
                "
                matTooltipPosition="above"
                class="pointer mr-3 mx-2"
                [icon]="eyeIcon"
                (click)="openPublicProfilePreview(user)"
              >
              </fa-icon>
            </div>
          </div>
          <div class="d-flex">
            <div class="mx-1"><b>Address:</b></div>
            <div class="mx-1">
              {{
                user.patron
                  ? (
                      ''.concat(user.patron?.address1 || '') +
                      ' '.concat(user.patron?.province || '') +
                      ' '.concat(user.patron?.country || '') +
                      ' '.concat(user.patron?.postalCode || '')
                    ).trim() || '--'
                  : (
                      ''.concat(user.organization?.address?.address || '') +
                      ' '.concat(user.organization?.province || '') +
                      ' '.concat(user.organization?.country || '') +
                      ' '.concat(user.organization?.postalCode || '')
                    ).trim() || '--'
              }}
            </div>
          </div>
          <div class="d-flex">
            <div class="mx-1">
              <b>{{ user.organization ? 'Province' : 'City / Province' }} </b>
            </div>
            <div class="mx-1">
              {{
                user.patron
                  ? (
                      ''.concat(user.patron?.city || '') +
                      ' / '.concat(user.patron?.province || '')
                    ).trim() || '--'
                  : (user.organization?.address?.province || '').trim() || '--'
              }}
            </div>
          </div>
          <div class="d-flex">
            <div class="mx-1"><b>Country</b></div>
            <div class="mx-1">
              {{
                user.patron
                  ? ''.concat(user.patron?.country || '').trim() || '--'
                  : (user.organization?.address?.country || '').trim() || '--'
              }}
            </div>
          </div>
          <div
            *ngIf="user.patron?.phoneMobile"
            class="d-flex"
          >
            <div class="mx-1"><b>Mobile Number:</b></div>
            <div class="mx-1">
              {{
                user.patron?.phoneMobile ||
                  user.organization?.phoneNumber ||
                  '--'
              }}
            </div>
          </div>
          <div class="d-flex">
            <div class="mx-1"><b>Phone Number:</b></div>
            <div class="mx-1">
              {{
                user.patron?.phonePersonal ||
                  user.organization?.phoneNumber ||
                  '--'
              }}
            </div>
          </div>

          <div
            class="orgInfoContainer d-flex"
            *ngIf="user.organization"
          >
            <div class="firstContainer col-5 px-0">
              <div class="col-12 px-0 d-flex mx-1">
                <b>is Reseller:</b>
                <div class="mx-2">
                  {{ user.organization?.isReseller || 'false' }}
                  <fa-icon
                    class="pointer"
                    [matTooltip]="
                      'Controls whether the organization can resell our tokens'
                    "
                    [icon]="changeIcon"
                    (click)="
                      changeResellerStatus(
                        user.id,
                        !user.organization.isReseller
                      )
                    "
                  ></fa-icon>
                </div>
              </div>

              <div
                class="col-12 px-0 d-flex mx-1"
                *ngIf="user.organization?.isReseller"
              >
                <b>Allow subscription:</b>
                <div class="mx-2">
                  {{ user.organization?.allowSubscription || 'false' }}
                  <fa-icon
                    class="pointer"
                    matTooltip="Controls whether the reseller can offer subscription options."
                    [icon]="changeIcon"
                    (click)="
                      changeSubscriptionAuthorization(
                        user.id,
                        !user.organization.allowSubscription
                      )
                    "
                  >
                  </fa-icon>
                </div>
              </div>

              <div
                class="col-12 px-0 d-flex mx-1"
                *ngIf="
                  user.organization?.isReseller ||
                  user.organization?.isB2CProvider
                "
              >
                <b>Is Trusted Org:</b>
                <div
                  *ngIf="
                    user.organization?.isReseller ||
                    user.organization?.isB2CProvider
                  "
                  class="mx-2"
                >
                  {{ user.organization?.isTrusted || 'false' }}
                  <fa-icon
                    class="pointer"
                    [matTooltip]="
                      'An organization marked as isTrusted can have negative tokens after the purchase by an outsider/b2c.'
                    "
                    [icon]="changeIcon"
                    (click)="
                      changeTrustedStatus(user.id, !user.organization.isTrusted)
                    "
                  ></fa-icon>
                </div>
              </div>

              <div
                class="col-12 px-0 d-flex mx-1"
                *ngIf="
                  (user.organization?.isTrusted &&
                    user.organization?.isReseller) ||
                  user.organization?.isB2CProvider
                "
              >
                <b>Max Debit:</b>
                <span
                  class="maximumTokenDebitInput col d-flex align-items-center mx-2"
                  *ngIf="!loadingDebits"
                >
                  <input
                    type="number"
                    max="0"
                    [(ngModel)]="maxTokenDebit"
                  />
                  <button
                    class="saveButton mx-3"
                    [matTooltip]="
                      'The maximum negative tokens that the organization can have.\n
                    Note: Needs to have isTrusted set to true.'
                    "
                    [ngStyle]="{ 'font-size': '12px' }"
                    (click)="saveMaxDebit(user.id)"
                  >
                    Save
                  </button>
                </span>
                <div class="col mx-2">
                  <fa-icon
                    [icon]="spinnerIcon"
                    [spin]="true"
                    class="text-white mr-2"
                    *ngIf="loadingDebits"
                  ></fa-icon>
                </div>
              </div>

              <div
                class="col-12 px-0 d-flex mx-1"
                *ngIf="user.organization || user.patron"
              >
                <b>Email verified: </b>
                <div class="mx-3">
                  {{ user.emailVerified || 'false' }}
                  <fa-icon
                    matTooltip="Resend email"
                    matTooltipPosition="above"
                    *ngIf="!user.emailVerified"
                    class="pointer"
                    [icon]="reSend"
                    (click)="resendEmail(user)"
                  ></fa-icon>
                </div>
              </div>

              <div
                class="col-12 px-0 d-flex mx-1"
                *ngIf="user.organization"
              >
                <b>Certified provider: </b>
                <div class="mx-3">
                  {{ user.organization.certifiedProvider || 'false' }}
                  <fa-icon
                    class="pointer"
                    [icon]="changeIcon"
                    (click)="
                      toggleCertifiedProviderState(
                        user.id,
                        !user.organization.certifiedProvider
                      )
                    "
                    matTooltip="Controls whether the organization will be marked as certified on 
                    it's public profile at https:///lsworks.org/find-provider"
                  >
                  </fa-icon>
                </div>
              </div>
            </div>

            <div class="secondContainer col-5 px-0">
              <div class="col-12 px-0 d-flex">
                <b>is B2C Provider:</b>
                <div
                  class="mx-3 d-flex justify-content-center align-items-center"
                >
                  {{ user.organization?.isB2CProvider || 'false' }}
                  <fa-icon
                    class="pointer"
                    [icon]="changeIcon"
                    [matTooltip]="
                      'Controls whether the organization can resell tokens in the B2C model.'
                    "
                    (click)="
                      setB2CProvider(user.id, !user.organization.isB2CProvider)
                    "
                  ></fa-icon>

                  <button
                    class="saveButton mx-3"
                    [ngStyle]="{ 'font-size': '12px' }"
                    (click)="openB2cPaymentOptionsDialog(user.organization)"
                  >
                    Payment types
                  </button>
                </div>
              </div>

              <div
                class="col-12 px-0 d-flex"
                *ngIf="user.organization?.isB2CProvider"
              >
                <b>Disable B2C to public:</b>
                <div class="mx-3">
                  {{ user.organization?.isPrivateB2C || 'false' }}
                  <fa-icon
                    class="pointer"
                    [matTooltip]="
                      'Controls whether the registration of a customer in the B2C model should be blocked to the public.'
                    "
                    [icon]="changeIcon"
                    (click)="
                      updateOrg(user.id, !user.organization.isPrivateB2C)
                    "
                  ></fa-icon>
                </div>
              </div>

              <div
                class="col-12 px-0 d-flex"
                *ngIf="user.organization?.publicProfile"
              >
                <b>Enable public profile:</b>
                <div class="mx-3">
                  {{ user.organization?.publicProfile?.enabled || 'false' }}
                  <fa-icon
                    class="pointer"
                    matTooltip="Controls whether the organization's public profile will be visible at https://lsworks.org/find-provider"
                    [icon]="changeIcon"
                    (click)="
                      changePublicProfileState(
                        user.id,
                        !user.organization?.publicProfile.enabled
                      )
                    "
                  ></fa-icon>
                </div>
              </div>

              <div class="col-12 px-0 d-flex">
                <b>is Outsider:</b>
                <div class="col mx-2">
                  {{ user.organization?.isOutsider || 'false' }}
                  <fa-icon
                    class="pointer"
                    [matTooltip]="
                      'Controls whether the organization is an outsider.'
                    "
                    [icon]="infoIcon"
                  ></fa-icon>
                </div>
              </div>

              <div
                class="col-12 px-0 d-flex align-items-center"
                *ngIf="
                  user.organization?.isReseller ||
                  user.organization?.isB2CProvider
                "
              >
                <b>Disable stripe warning:</b>

                <mat-checkbox
                  *ngIf="!loadingDebits"
                  [(ngModel)]="disableStripeWarning"
                  [matTooltip]="
                    'Controls whether we should allow a reseller or B2C provider to access the portal without creating an account on Stripe.'
                  "
                  class="mx-2"
                  (click)="
                    changeStripeWarningStatus(user.id, disableStripeWarning)
                  "
                >
                </mat-checkbox>

                <fa-icon
                  [icon]="spinnerIcon"
                  [spin]="true"
                  class="text-white ml-2 mx-2"
                  *ngIf="loadingDebits"
                ></fa-icon>
              </div>
            </div>
          </div>

          <hr />
          <div *ngIf="user.organization">
            <h3 class="my-2">Users</h3>
            <div class="tokenList aCenter col-12">
              <div class="listHeader col-12 d-flex">
                <div class="col-4">NAME</div>
                <div class="col-5">EMAIL</div>
                <div class="col-3">ROLE</div>
              </div>
              <div
                class="listRow col-12 d-flex"
                *ngFor="let orgUser of user.users"
              >
                <div class="col-4">
                  {{
                    orgUser.patron.givenName + ' ' + orgUser.patron.familyName
                  }}
                </div>
                <div class="col-5">
                  {{ orgUser.email }}
                </div>
                <div class="col-3">
                  {{ orgUser.role }}
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div *ngIf="user.organization?.isReseller">
            <h3 class="my-2">Outsiders</h3>
            <div class="tokenList aCenter col-12">
              <div class="listHeader col-12 d-flex">
                <div class="col-4">NAME</div>
                <div class="col-5">EMAIL</div>
                <div class="col-2">ACTION</div>
              </div>
              <div
                class="listRow col-12 d-flex"
                *ngFor="let outsider of orgOutsiders"
              >
                <div class="col-4">
                  {{ outsider.organization?.name }}
                </div>
                <div class="col-5">
                  {{ outsider.email }}
                </div>
                <div class="col-1 align-right mx-1">
                  <fa-icon
                    class="pointer"
                    [icon]="searchIcon"
                    (click)="
                      selectBelongsTo(outsider.organization.id, 'Organizations')
                    "
                  >
                  </fa-icon>
                </div>
                <div class="col-1 align-left mx-1">
                  <fa-icon
                    class="pointer"
                    [icon]="minusLine"
                    (click)="removeOutsider(outsider)"
                    matTooltip="Remove outsider"
                    matTooltipPosition="above"
                  >
                  </fa-icon>
                </div>
                <div
                  class="col-1 align-left"
                  *ngIf="!outsider.stripeSetup && !outsiderLoading"
                >
                  <fa-icon
                    class="pointer"
                    [icon]="warning"
                    matTooltip="Fix stripe setup"
                    matTooltipPosition="above"
                    (click)="fixStripeSetup(outsider)"
                  >
                  </fa-icon>
                </div>
              </div>
            </div>
            <div class="d-flex col-12 listRow outsiderList">
              <form [ngStyle]="{ width: '50%' }">
                <mat-form-field class="full-width">
                  <mat-label>Organizations to add as outsider</mat-label>
                  <input
                    type="text"
                    placeholder="Start typing"
                    aria-label="organization"
                    matInput
                    [formControl]="organizationAutocomplete"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete
                    autoActiveFirstOption
                    #auto="matAutocomplete"
                    (optionSelected)="selectOutsiderToAdd($event.option.value)"
                  >
                    <mat-option
                      *ngFor="
                        let option of organizationAutocompleteFilteredOptions
                          | async
                      "
                      [value]="option"
                    >
                      {{ option.organization.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>

              <fa-icon
                class="pointer margin-top-15 mx-2"
                [icon]="plusLine"
                (click)="addOutsider()"
              ></fa-icon>

              <app-simple-button
                (click)="openOutsiderTransferDialog(user.organization)"
                class="mx-auto pt-2"
                [text]="'Import outsiders from organization'"
              >
              </app-simple-button>
            </div>
          </div>

          <hr />

          <div *ngIf="user.organization">
            <h3 class="my-2">Clients</h3>
            <div class="tokenList aCenter col-12">
              <div class="listHeader col-12 d-flex">
                <div class="col-4">NAME</div>
                <div class="col-5">EMAIL</div>
                <div class="col-3">ACTION</div>
              </div>
              <div
                class="listRow col-12 d-flex"
                *ngFor="let clients of orgClients"
              >
                <div class="col-4">
                  {{
                    clients.patron.givenName + ' ' + clients.patron.familyName
                  }}
                </div>
                <div class="col-5">
                  {{ clients.email }}
                </div>
                <div class="col-3">
                  <fa-icon
                    class="pointer"
                    [icon]="searchIcon"
                    (click)="selectBelongsTo(clients.patron.id, 'Clients')"
                  >
                  </fa-icon>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div *ngIf="user.organization">
            <h3 class="my-2">Students</h3>
            <div class="tokenList aCenter col-12">
              <div class="listHeader col-12 d-flex">
                <div class="col-3">NAME</div>
                <div class="col-3">CLIENT</div>
                <div class="col-3">ARCHIVED</div>
                <div class="col-3">ACTION</div>
              </div>
              <div
                class="listRow col-12 d-flex"
                *ngFor="let student of orgStudents"
              >
                <div class="col-3">
                  {{
                    (student.givenName || '') + ' ' + (student.familyName || '')
                  }}
                </div>
                <div class="col-3">
                  {{
                    (student.belongsTo?.givenName || '') +
                      ' ' +
                      (student.belongsTo?.familyName || '')
                  }}
                </div>
                <div class="col-3">
                  {{ student.archived }}
                </div>
                <div class="col-3">
                  <fa-icon
                    class="pointer"
                    [icon]="searchIcon"
                    (click)="selectBelongsTo(student.id, 'Students')"
                  >
                  </fa-icon>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <h3 class="my-2">Tokens</h3>
          <div class="tokenList aCenter col-12 px-0">
            <div class="listHeader col-12 d-flex px-0">
              <div class="col-2 px-0">PROGRAM</div>
              <div class="col-3 px-0">PAYMENT CONFIRMED</div>
              <div class="col-3 px-0">ALLOW COMPLETE AT HOME</div>
              <div class="col-3 px-0">ALLOW RERUN</div>
              <div class="col-1 px-0">ACTIONS</div>
            </div>
            <div
              class="listRow col-12 d-flex"
              *ngFor="let token of currentTokens"
            >
              <div class="col-2 px-0">
                {{ getProgramById(token.programs[0])?.name }}
              </div>
              <div class="col-3 px-0">
                <span *ngIf="token.paymentConfirmed">Yes</span>
                <span *ngIf="!token.paymentConfirmed">No</span>
              </div>
              <div class="col-3 px-0">
                <span *ngIf="token.allowCompleteAtHome">Yes</span>
                <span *ngIf="!token.allowCompleteAtHome">No</span>
              </div>
              <div class="col-3 px-0">
                <span *ngIf="token.allowProgressRerun">Yes</span>
                <span *ngIf="!token.allowProgressRerun">No</span>
              </div>
              <div class="col-1 px-0 d-flex justify-content-center">
                <div>
                  <fa-icon
                    class="mx-2"
                    *ngIf="!loading"
                    (click)="deleteToken(token)"
                    [icon]="minusLine"
                  ></fa-icon>
                  <fa-icon
                    matTooltip="Access student page"
                    class="mx-2"
                    *ngIf="loading"
                    [icon]="spinnerIcon"
                    [spin]="true"
                  ></fa-icon>
                </div>

                <div>
                  <fa-icon
                    (click)="openUpdateTokenDialog(token)"
                    [icon]="edit"
                  ></fa-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="col-3 aCenter mx-3">
              <div
                class="credits m-auto action"
                (click)="addTokenTo(user)"
              >
                Add Token
              </div>
            </div>

            <div class="col-3 aCenter mx-3">
              <div
                class="credits m-auto action"
                (click)="deleteTokenList(user)"
              >
                Delete Token
              </div>
            </div>

            <div class="col-3 aCenter mx-3">
              <div
                class="credits m-auto"
                [ngClass]="{ 'negative-tokens': currentCredits < 0 }"
              >
                {{ currentCredits }} Credits
              </div>
            </div>
          </div>

          <hr />

          <div *ngIf="user.organization === undefined">
            <h3 class="my-2">Students</h3>
            <div
              class="d-flex spinner-row"
              *ngIf="loadingStudents"
            >
              <fa-icon
                class="col-12 aCenter"
                [spin]="true"
                size="3x"
                [icon]="spinnerIcon"
              >
              </fa-icon>
            </div>
            <div
              class="studentList aCenter col-12"
              *ngIf="!loadingStudents"
            >
              <div class="listHeader col-12 d-flex p-2">
                <div class="col-5">NAME</div>
                <div class="col-5">TOKENS</div>
                <div class="col-2">ACTIONS</div>
              </div>
              <div
                class="listRow col-12"
                *ngFor="let student of currentStudents"
              >
                <div class="d-flex col-12 p-2">
                  <div class="col-5">
                    {{ student?.givenName }} {{ student?.familyName }}
                  </div>
                  <div class="col-5">
                    {{ student?.tokens.length }}
                  </div>
                  <div class="col-2">
                    <fa-icon
                      *ngIf="selectedStudent === student.id"
                      (click)="deselectStudent(student.id)"
                      [icon]="minusLine"
                      class="mx-2"
                    >
                    </fa-icon>

                    <fa-icon
                      *ngIf="selectedStudent !== student.id"
                      (click)="selectStudent(student.id)"
                      [icon]="plusLine"
                      class="mx-2"
                    >
                    </fa-icon>

                    <fa-icon
                      *ngIf="selectedStudent !== student.id"
                      (click)="accessStudentProgramPage(student.id)"
                      matTooltip="Access student page"
                      [icon]="game"
                      class="mx-2"
                    >
                    </fa-icon>

                    <fa-icon
                      matTooltip="Complete student progress"
                      *ngIf="selectedStudent !== student.id"
                      (click)="openProgressCompletionDialog(student)"
                      [icon]="upgrade"
                      class="mx-2"
                    >
                    </fa-icon>

                    <fa-icon
                      *ngIf="loading"
                      [icon]="spinnerIcon"
                      [spin]="true"
                      class="mx-2"
                    ></fa-icon>

                    <fa-icon
                      *ngIf="studentHaveBrokenProgress(student.progress)"
                      (click)="fixStudentProgress(student)"
                      class="pointer mx-2"
                      [icon]="warning"
                      matTooltip="Fix student progress"
                      matTooltipPosition="above"
                    >
                    </fa-icon>
                  </div>
                </div>

                <div
                  class="col-12 studentList mt-2 mb-0"
                  *ngIf="selectedStudent === student.id"
                >
                  <h4 class="my-2">Progress</h4>
                  <div class="listHeader col-12 d-flex p-2">
                    <div class="col">TAG</div>
                    <div class="col">SESSION</div>
                    <div class="col">DATE</div>
                    <div class="col">METADATA</div>
                    <div class="col">ACTIONS</div>
                  </div>
                  <div
                    class="listRow col-12 d-flex p-2"
                    *ngFor="let progress of student.progress"
                  >
                    <div class="col capitalize">
                      {{ progress.tag }}
                    </div>
                    <div class="col">
                      {{ progress.session }}
                    </div>
                    <div class="col">
                      {{ formatDate(progress.creationTime) }}
                    </div>
                    <div class="col metadataRow">
                      <button (click)="openMetadataInfo(progress, student)">
                        Open
                      </button>
                    </div>
                    <div class="col">
                      <fa-icon
                        *ngIf="!loading"
                        (click)="deleteProgress(progress)"
                        [icon]="minusLine"
                        class="mx-1"
                      ></fa-icon>

                      <fa-icon
                        *ngIf="!loading"
                        (click)="openEditProgressDialog(progress)"
                        [icon]="edit"
                        class="mx-1"
                      ></fa-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 class="my-2">Charges</h3>
          <div class="d-flex spinner-row">
            <fa-icon
              *ngIf="loadingCharges"
              class="col-12 aCenter"
              [spin]="true"
              size="3x"
              [icon]="spinnerIcon"
            ></fa-icon>
          </div>
          <div
            *ngIf="loadingCharges === false"
            class="studentList aCenter col-12"
          >
            <div class="listHeader col-12 d-flex">
              <div class="col-4">CREATED</div>
              <div class="col-2">AMOUNT</div>
              <div class="col-2">REFUNDED</div>
              <div class="col-2">SUBSCRIPTION</div>
              <div class="col-2">ACTIONS</div>
            </div>

            <div
              class="listRow col-12 d-flex"
              *ngFor="let currentCharge of chargesList"
            >
              <div class="col-4">
                <!-- the * 1000 is to convert from unix time stamp to the regular one -->
                {{ currentCharge.created * 1000 | date }}
              </div>
              <div class="col-2">
                {{
                  currentCharge.amount / 100
                    | currency: currentCharge.currency.toUpperCase():'symbol'
                }}
              </div>
              <div class="col-2">
                {{ currentCharge.refunded }}
              </div>
              <div class="col-2">
                {{ checkIfIsSubscription(currentCharge) }}
              </div>
              <div class="col-2">
                <fa-icon
                  *ngIf="!currentCharge.refunded"
                  (click)="refund(currentCharge.id)"
                  class="col mx-1"
                  [icon]="refundIcon"
                >
                </fa-icon>
                <fa-icon
                  class="col mx-1"
                  [icon]="receipt"
                  (click)="openReceipt(currentCharge.receipt_url)"
                >
                </fa-icon>
                <fa-icon
                  [ngClass]="{
                    disabledIcon: !checkIfIsSubscription(currentCharge)
                  }"
                  (click)="openSubscriptionDialog(currentCharge)"
                  class="col mx-1"
                  [icon]="infoIcon"
                >
                </fa-icon>
              </div>
            </div>
          </div>
          <h3
            class="my-2"
            *ngIf="user.organization && !user.organization.isOutsider"
          >
            Regular bundles
          </h3>
          <div
            class="bundlesRow col-12"
            *ngIf="
              user.organization &&
              !user.organization.isOutsider &&
              regularBundles
            "
          >
            <div
              class="col-12 px-0 mx-1"
              *ngFor="let bundle of regularBundles; let i = index"
            >
              <app-default-bundle-card
                (saveEvent)="saveBundle($event)"
                (bundleToDefaultEvent)="setRegularBundleToDefault($event)"
                [bundle]="bundle"
                [customRegularBundles]="customRegularBundles"
                [user]="user"
                [order]="bundle.order !== undefined ? bundle.order : i"
              >
              </app-default-bundle-card>
            </div>
          </div>
          <hr />
          <div class="d-flex">
            <div class="col-12 aCenter">
              <button
                class="warn"
                (click)="deleteUser(user.id, user.tokenId)"
              >
                Delete User
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="col pagination">
          <app-pagination
            [pageSize]="15"
            [items]="usersList"
            (changePage)="onChangePage($event)"
          ></app-pagination>
        </div>
      </div>
    </div>

    <div
      class="userList col-12"
      *ngIf="selectedUserType === 'Students'"
    >
      <div
        class="userRow col-12 p-4"
        *ngFor="let user of filteredItens"
        [ngClass]="{ client: user.patron, org: user.organization }"
      >
        <div class="d-flex p-3">
          <div class="col-4">{{ user.fullname }}</div>
          <div class="col-4">
            <span>
              {{
                (user.belongsTo?.givenName || '') +
                  ' ' +
                  (user.belongsTo?.familyName || '')
              }}
            </span>
            <fa-icon
              class="pointer"
              *ngIf="user.belongsTo"
              [icon]="searchIcon"
              (click)="selectBelongsTo(user.belongsTo.id, 'Clients')"
            >
            </fa-icon>
          </div>
          <div class="col-2">{{ user.archived ? 'Archived' : '' }}</div>
          <div class="openClose offset-1 col-1">
            <fa-icon
              *ngIf="selectedUser !== user.id"
              (click)="selectUser(user.id)"
              [icon]="plusLine"
            ></fa-icon>
            <fa-icon
              *ngIf="selectedUser === user.id"
              (click)="deselectUser()"
              [icon]="minusLine"
            ></fa-icon>
          </div>
        </div>

        <div
          class="col-12 mx-0 px-4"
          *ngIf="selectedUser === user.id"
        >
          <hr />
          <div class="d-flex">
            <div class="mx-1"><b>Birthdate:</b></div>
            <div class="mx-1">{{ user.birthdate | date }}</div>
          </div>
          <div class="d-flex">
            <div class="mx-1"><b>Language:</b></div>
            <div class="mx-1">{{ user.language }}</div>
          </div>
          <div class="d-flex">
            <div class="mx-1"><b>Archived:</b></div>
            <div class="mx-1">{{ user.archived }}</div>
          </div>
          <br />
          <div class="d-flex">
            <div class="mx-1"><b>Image:</b></div>
            <img
              class="image mx-3"
              [src]="user.image"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col pagination">
          <app-pagination
            [pageSize]="15"
            [items]="usersList"
            (changePage)="onChangePage($event)"
          ></app-pagination>
        </div>
      </div>
    </div>
  </div>
  <div
    class="loading"
    *ngIf="!clients || !organizations"
  >
    <fa-icon
      [icon]="spinnerIcon"
      [spin]="true"
      size="4x"
    >
    </fa-icon>
  </div>
</app-main-layout>
