import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { get } from 'lodash';
import { AuthService } from '../../../services/auth/auth.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { OrganizationControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class PurchaseGuard {
  constructor(
    private _auth: AuthService,
    private _router: Router,
    private organizationService: OrganizationControllerService,
    private roles: RoleService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this._auth.getUser();
    const account = this._auth.getOrgAcc();

    const isOutsider = get(account, 'organization.isOutsider', false);
    const isSchool = get(account, 'organization.isSchool', false);
    const isB2c = this.roles.isB2C();
    const isAdmin = this.roles.isOrgAdmin();

    let allowPurchase = true;

    // Only organizations, B2C or org admins can access the purchase page
    if (!user.organization && !isB2c && !isAdmin) {
      allowPurchase = false;
    }

    // if the user is an outsider, check if the reseller org have the stripe disabled
    if (isOutsider) {
      const orgId = account.organization.id;
      const reseller = await firstValueFrom(this.organizationService.organizationControllerGetReseller(orgId));
      allowPurchase = !get(reseller, 'disableStripeWarning', false);
    }

    // if the user is a b2c or org admin, check if the org owner have the stripe disabled
    if (isB2c || isAdmin) {
      allowPurchase = !get(account, 'organization.disableStripeWarning', false);
    }

    // if the user is from a school, dont allow access to the purchase page
    if (isSchool) {
      allowPurchase = false;
    }

    if (!allowPurchase) {
      this._router.navigate(['users']);
      return false;
    }

    return true;
  }
}
