import { Component, OnInit } from '@angular/core';
import { faSpinner, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalConfiguration } from '../../../interfaces/global-config.interfaces';
import { Organization, SchoolBoard, SchoolBoardControllerService } from 'src/app/core/openapi';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SchoolBoardCreationDialog } from './school-board-creation-dialog/school-board-creation-dialog';

@Component({
  selector: 'app-school-board',
  templateUrl: './school-boards.component.html',
  styleUrls: ['./school-boards.component.scss'],
})
export class SchoolBoardComponent implements OnInit {
  public readonly loadingIcon: IconDefinition = faSpinner;
  public readonly trash: IconDefinition = faTrash;
  public readonly edit: IconDefinition = faEdit;

  public globalConfigurations: GlobalConfiguration;
  public loading: boolean = true;

  public schoolBoards: SchoolBoard[] = [];
  public orgList: Organization[];

  public readonly schoolBoardColumns: string[] = ['name', 'orgId', 'actions'];

  constructor(
    private schoolBoardService: SchoolBoardControllerService,
    protected _snackBar: MatSnackBar,
    private _dialog: MatDialog,
  ) {}

  async ngOnInit() {
    await this.getSchoolBoards();
    this.loading = false;
  }

  public setOrganizations(orgList: Organization[]) {
    this.orgList = orgList;
  }

  public editSchoolBoard(schoolBoard: SchoolBoard) {
    const dialog = this._dialog.open(SchoolBoardCreationDialog, {
      width: '500px',
      height: '557px',
      data: {
        orgList: this.orgList,
        schoolBoard,
      },
    });

    dialog.afterClosed().subscribe(async (shouldRefresh: boolean) => {
      if (shouldRefresh) {
        await this.refresh();
      }
    });
  }

  public openCreationDialog() {
    const dialog = this._dialog.open(SchoolBoardCreationDialog, {
      width: '500px',
      height: '557px',
      data: {
        orgList: this.orgList,
      },
    });

    dialog.afterClosed().subscribe(async (shouldRefresh: boolean) => {
      if (shouldRefresh) {
        await this.refresh();
      }
    });
  }

  public async getSchoolBoards() {
    const schoolBoards = await firstValueFrom(this.schoolBoardService.schoolBoardControllerGetSchoolBoards());

    if (!schoolBoards) {
      this.schoolBoards = [];
    }

    this.schoolBoards = schoolBoards;
  }

  public async deleteSchoolBoard(id: string) {
    try {
      const response = await firstValueFrom(this.schoolBoardService.schoolBoardControllerDeleteSchoolBoardService(id));

      if (!response) {
        throw new Error('Could not delete the school board');
      }

      this._snackBar.open('School board delete!', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      await this.refresh();
    } catch (error) {
      this._snackBar.open(error.message, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public async refresh() {
    this.loading = true;
    await this.ngOnInit();
  }
}
