import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AdminsListService } from './admins-list.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { UsersHelperService } from '../../../users-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { OrgAdminAndManagerCreateModalComponent } from '../../../modals/org-admin-manager-create-modal/org-admin-manager-create-modal.component';
import { firstValueFrom, Subject } from 'rxjs';
import { AdminManager, ManagerActions, Templates, UsersType } from 'src/app/shared/interfaces/Manager.interface';
import { applyFilter } from 'src/app/shared/helpers/applyFilter';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { Patron } from 'src/app/core/openapi/model/patron';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { AccountControllerService } from 'src/app/core/openapi';
import { UserTypeEnum } from '../../../components/manage-user/mock-data/user-type.enum';
interface Admin {
  name: string;
  emailVerified?: string;
  id?: string;
  patron?: Patron;
}

@Component({
  selector: 'users-admins-list',
  templateUrl: './admins-list.component.html',
  styleUrls: ['./admins-list.component.scss'],
})
export class AdminsListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  protected template: Templates = Templates.TABLE;
  protected dataSource = new MatTableDataSource();
  public admins: Admin[];
  public isDropdownOpen: boolean = false;

  constructor(
    private _adminsListService: AdminsListService,
    private _logger: LoggerService,
    private _confirm: ConfirmationService,
    private _rest: RestAPIService,
    private _usersService: UsersHelperService,
    private _dialog: MatDialog,
    private authService: AuthService,
    private excelService: ExcelService,
    private accountController: AccountControllerService,
  ) {
    this._adminsListService.currentAdmin.subscribe(async (newAdmin) => {
      if (newAdmin) {
        await this.loadAdmins();
      }
    });
  }

  async ngOnInit() {
    this.template = Templates.LOADING;
    const data = await this._adminsListService.getAdmins();
    this.admins = data;
    this.dataSource = new MatTableDataSource(data);
    this.template = Templates.TABLE;
  }

  async loadAdmins() {
    this.template = Templates.LOADING;
    const data = await this._adminsListService.getAdmins({ refresh: true });
    this.dataSource = new MatTableDataSource(data);
    this.template = Templates.TABLE;
  }

  public createAdmin() {
    this._dialog.open(OrgAdminAndManagerCreateModalComponent, {
      data: {
        type: ManagerActions.CREATE,
        admin: {
          type: UsersType.ADMIN,
        },
      },
    });
  }
  public editAdmin(admin: AdminManager) {
    this._dialog.open(OrgAdminAndManagerCreateModalComponent, {
      data: {
        type: ManagerActions.UPDATE,
        admin: admin,
      },
    });
  }

  public resendEmailVerification(admin: AdminManager) {
    this._adminsListService.resendAdminVerificationEmail(admin);
  }

  public async deleteAdmin(admin: AdminManager) {
    this._confirm
      .createConfirmation('Warning', 'Are you sure you want to delete this admin?', 'Yes', 'No')
      .then(async () => {
        try {
          await this._rest.delete('patron/' + admin.patron.id, { msg: 'Could not delete admin.' });

          const data = await this._adminsListService.getAdmins({ refresh: true });
          this.dataSource = new MatTableDataSource(data);
        } catch (err) {
          this._logger.error(err);
        }
      });
  }

  public applyFilter(event: Event): void {
    this.dataSource = applyFilter(event, this.dataSource);
  }

  getEmailVerifiedTooltip(isEmailVerified: boolean | undefined): string {
    return this._usersService.getEmailVerifiedTooltip(isEmailVerified);
  }

  public isDataSourceEmpty(): boolean {
    return this.dataSource.data.length === 0;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public triggerFileInput() {
    const fileInput = document.getElementById('fileInputAdmin') as HTMLInputElement;
    fileInput.click();
  }

  public closeDropdown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const dropdown = document.querySelector('.dropdown-container');

    if (dropdown && !dropdown.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

  public async importAdmins(e: Event) {
    try {
      const user = await this.authService.getUser();
      const org = get(user, 'organization', {});

      const target = e.target as HTMLInputElement;
      const files = target.files;

      if (!files?.length) {
        this._dialog.open(AlertDialogComponent, {
          width: '400px',
          data: 'No file selected. Please select a file to import.',
        });
        return false;
      }

      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await firstValueFrom(
        this.accountController.accountControllerAccountImport(UserTypeEnum.OrganizationAdmin, org.id, file),
      );
      if (response?.status === 200) {
        this.toggleDropdown();
        this._dialog.open(AlertDialogComponent, {
          width: '600px',
          data: response.message,
        });
        this.template = Templates.LOADING;
        await this.loadAdmins();
        this.template = Templates.TABLE;
      }
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error importing admins: ' + error.message,
      });
      this.toggleDropdown();
    }
  }

  public async downloadFileExampleAdmins() {
    try {
      const admins = [
        {
          fullname: 'Liam Smith',
          email: 'liamsmith@example.com',
        },
      ];

      this.excelService.downloadFileExample(admins, 'admins', 'ImportAdminsFileExample');
      this.toggleDropdown();
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error creating sample spreadsheet: ' + error.message,
      });
    }
  }

  public async exportAdmins() {
    if (!this.admins?.length) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'No admins to export.',
      });
      return;
    }
    try {
      const adminsData = this.admins.map((admin) => {
        return {
          fullname: `${get(admin.patron, 'givenName', '')}  ${get(admin.patron, 'familyName', '')}`,
          givenName: get(admin.patron, 'givenName', ''),
          familyName: get(admin.patron, 'familyName', ''),
          email: get(admin, 'email', ''),
          status: get(admin, 'status', ''),
        };
      });

      this.excelService.exportData(adminsData, 'admins', 'Admins');
      this.toggleDropdown();
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error exporting admins: ' + error.message,
      });
    }
  }
}
