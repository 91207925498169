import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { TableProgramTagComponent } from '../../../components/table-program-tag/table-program-tag.component';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { StudentsListService } from './students-list.service';
import { UsersHelperService } from '../../../users-helper.service';
import { isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { StudentCreateModalComponent } from '../../../modals/student-create-modal/student-create-modal.component';
import { Subject, Subscription } from 'rxjs';
import { ManagerActions } from 'src/app/shared/interfaces/Manager.interface';
import { applyFilter } from 'src/app/shared/helpers/applyFilter';

@Component({
  selector: 'users-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.scss'],
})
export class StudentsListComponent implements OnInit {
  private destroy$ = new Subject<void>();
  template: 'unselected' | 'loading' | 'loaded' | 'noStudents' = 'unselected';

  dataSource: MatTableDataSource<any>;

  currentClientId: string;

  private refreshSubscription: Subscription;
  public isDropdownOpen: boolean = false;

  @ViewChild(TableProgramTagComponent) programTagComponent: TableProgramTagComponent;

  constructor(
    private _studentsListService: StudentsListService,
    private _confirm: ConfirmationService,
    private _usersHelperService: UsersHelperService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subscribeEvents();
  }

  public subscribeEvents() {
    this.refreshSubscription = this._studentsListService.refreshStudents.subscribe(async () => {
      this.template = 'loading';
      const students = await this._studentsListService.showStudents(this.currentClientId, { refresh: true });
      students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');
      this.dataSource = new MatTableDataSource(students);
    });
  }

  async getStudents(id: string): Promise<void> {
    // Check if current client is not already selected
    if (this.currentClientId !== id) {
      // Set template
      this.template = 'loading';

      // Save current client id
      this.currentClientId = id;

      // Get students
      const students = await this._studentsListService.showStudents(id, { refresh: true });

      // Check if client has students
      students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');

      // Update datasource
      this.dataSource = new MatTableDataSource(students);
    }
  }

  public editClientStudent(student: Student): void {
    this.dialog.open(StudentCreateModalComponent, {
      data: {
        type: ManagerActions.UPDATE,
        student: student,
        clientId: this.currentClientId,
      },
    });
  }

  public accessNeuralign(student: Student): void {
    this._usersHelperService.goToStudentProgramPage(student);
  }

  public async deleteClientStudent(student: Student): Promise<void> {
    const studentHasAssignedCredit = student.tokens?.length > 0;
    const studentHasProgress = student.progress.length > 0;

    if (studentHasAssignedCredit && !studentHasProgress) {
      this._confirm.createConfirmation(
        'Warning',
        'The assigned credit should be retrieved by clicking on the - sign before archiving the student.',
        'Close',
        undefined,
      );
    } else {
      this._confirm
        .createConfirmation(
          'Warning',
          `Are you sure you want to delete ${!isEmpty(student.fullname) ? student.fullname : 'this student'}?`,
          'Yes',
          'No',
          '350px',
        )
        .then(async () => {
          this.template = 'loading';
          await this._studentsListService.archiveStudent(student);

          const students = await this._studentsListService.showStudents(this.currentClientId, { refresh: true });

          students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');

          this.dataSource = new MatTableDataSource(students);

          students.length === 0 ? (this.template = 'noStudents') : (this.template = 'loaded');
        });
    }
  }

  public applyFilter(event: Event): void {
    this.dataSource = applyFilter(event, this.dataSource);
  }

  public handleAddStudent() {
    const dialogRef = this.dialog.open(StudentCreateModalComponent, {
      data: {
        type: ManagerActions.CREATE,
        clientId: this.currentClientId,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result?.student) {
        const updatedData = [...this.dataSource.data, result.student];
        this.dataSource.data = updatedData;
      }
    });
  }

  public canAccessNeuralign(student: Student): boolean {
    // a student should only be able to access Neuralign
    // if they have a credit spent on their enrollment
    return student.tokens?.length > 0;
  }

  public exportStudents(): void {
    this._studentsListService.exportStudents(this.dataSource.data);
    this.toggleDropdown();
  }

  public downloadStudentFile(): void {
    this._studentsListService.downloadStudentFile(true);
    this.toggleDropdown();
  }

  public async importStudents(event: Event) {
    const createdStudents = await this._studentsListService.importStudentsForFile(event);
    if (!createdStudents) {
      this.toggleDropdown();
      return;
    }
    if (this.currentClientId) {
      await this.getStudents(this.currentClientId);
    }
    this.toggleDropdown();
  }

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public triggerFileInput() {
    const fileInput = document.getElementById('fileInputStudents') as HTMLInputElement;
    fileInput.click();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
}
