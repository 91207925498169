import { Component, OnInit } from '@angular/core';
import { CoursesService } from '../../services/courses/courses.service';
import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CourseFEM } from '../../services/courses/courses.service.models';
import { Language } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { get } from 'lodash';

const DEFAULT_LANG = 'en_ca';

/**
 * @scope Holds all the components needed for the courses list page.
 */
@Component({
  selector: 'courses-course-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss'],
})
export class CoursesListPageComponent implements OnInit {
  courses$ = this._coursesService.allCourses$;

  public loading = true;
  public spinner: IconDefinition = faSpinner;

  public courseList: CourseFEM[];
  public courseListOriginal: CourseFEM[];
  public coursesByLanguage: Map<string, CourseFEM[]> = new Map();

  public languages: Language[] = [];
  public currentLanguage: string;

  constructor(private _coursesService: CoursesService) {}

  async ngOnInit() {
    this.courseList = await this._coursesService.initCourses();
    await this.splitCoursesByLanguage();
    const browserLang = this.getBrowserLanguage();
    this.currentLanguage = browserLang;
    this.loading = false;
  }

  public async splitCoursesByLanguage() {
    if (!this.courseList) return;
    this.courseListOriginal = [...this.courseList];
    this.coursesByLanguage.clear();
    this.languages = [];

    const languages = await this._coursesService.getLanguages();

    this.courseListOriginal = this.courseListOriginal.map((course) => ({
      ...course,
      language: course.language?.toLowerCase() ?? DEFAULT_LANG,
    }));

    languages.forEach((lang: Language) => {
      const coursesForLanguage = this.courseListOriginal.filter(
        (course) => course.language?.toLowerCase() === lang.languageCode.toLowerCase(),
      );

      if (coursesForLanguage.length > 0) {
        this.coursesByLanguage.set(lang.languageCode, coursesForLanguage);
        this.languages.push(lang);
      }
    });

    this.languageToggle(this.currentLanguage);
    this.alphabeticalOrderLanguage();
  }

  private languageToggle(languageCode: string) {
    this.courseList = this.coursesByLanguage.get(languageCode) ?? this.coursesByLanguage.get(DEFAULT_LANG) ?? [];
  }

  private alphabeticalOrderLanguage() {
    const defaultLangIndex = this.languages.findIndex((lang) => lang.languageCode === DEFAULT_LANG);

    if (defaultLangIndex !== -1) {
      const defaultLang = this.languages.splice(defaultLangIndex, 1)[0];
      this.languages.sort((a, b) => a.name.localeCompare(b.name));
      this.languages.unshift(defaultLang);
    } else {
      this.languages.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  public toggleLanguage(languageCode: string) {
    this.currentLanguage = languageCode;
    this.languageToggle(languageCode);
  }

  private getBrowserLanguage(): string {
    const browserLang = window.navigator.language.replace('-', '_').toLowerCase();
    const foundLanguage = this.languages.find((lang) => lang.languageCode === browserLang);
    if (foundLanguage) {
      const languageCode = get(foundLanguage, 'languageCode', DEFAULT_LANG);
      this.toggleLanguage(languageCode);
      return languageCode;
    }
  }
}
