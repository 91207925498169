import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkBook, WorkSheet, utils, write, read } from 'xlsx';
import { firstValueFrom, Subject } from 'rxjs';
import { UsersHelperService } from '../../../users-helper.service';
import { Client, UserType } from '../../../../../shared/interfaces';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { Student } from 'src/app/pages/students/interfaces/student.interface';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert/alert.dialog';
import { EXCEL_EXTENSION, EXCEL_TYPE } from 'src/app/services/excel/excel.constants';
import { validateStudentImportData } from 'src/app/shared/helpers/validateStudentImportData.function';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { get } from 'lodash';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AccountControllerService } from 'src/app/core/openapi';
import { UserTypeEnum } from '../../../components/manage-user/mock-data/user-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ClientsListService {
  private clientsUpdated = new Subject<void>();
  clients: Client[];

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _rest: RestAPIService,
    private _logger: LoggerService,
    private _snackBar: MatSnackBar,
    private _usersHelperService: UsersHelperService,
    private excelService: ExcelService,
    private authService: AuthService,
    private accountController: AccountControllerService,
  ) {}

  public async getClients(options?: { refresh: boolean }): Promise<Client[]> {
    try {
      if (options?.refresh || !this.clients) {
        const response = await this._rest.get(`/organization/self/patrons`);
        this.clients = response.patrons.map((client: Client) => ({
          ...client,
          name: this._usersHelperService.transformUserName(client),
          status: this._usersHelperService.getUserStatus(client.emailVerified),
          type: UserType.Client,
        }));
      }
      return this.clients;
    } catch (err) {
      this._logger.error(err);
    }
  }

  get clientsUpdated$() {
    return this.clientsUpdated.asObservable();
  }

  public notifyClientsUpdated() {
    this.clientsUpdated.next();
  }
  public addClientStudent(id: string): void {
    this._router.navigate([`/students/profile/${id}`]);
  }

  public editClient(client: Client): void {
    localStorage.setItem('LS_OrgEditingUser', JSON.stringify(client));
    this._router.navigate(['/users/manage/' + client.id]);
  }

  public resendClientVerificationEmail(client: Client): void {
    const userName = client.fullname ? client.fullname : client.name;

    this._rest
      .get('account/email/verify/resend/' + client.accountId, {
        msg: 'Could not get account/email/resend/:clientAccountId.',
      })
      .then(() => {
        this._snackBar.open(`Verification email has been sent to ${userName}`, 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      })
      .catch(() => {
        this._snackBar.open('E-mail not found', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public async deleteClient(id: string): Promise<void> {
    try {
      await this._rest.put('patron/archive/' + id, {}, { msg: 'Could not put patron/archive.' });
    } catch (err) {
      this._logger.error(err);
    }
  }
  public downloadStudentFile() {
    const workbook: WorkBook = { SheetNames: [], Sheets: {} };

    const students = [
      {
        givenName: '',
        familyName: '',
        nickname: '',
        language: 'en_ca',
      },
    ];

    workbook.SheetNames.push('students');
    workbook.Sheets['students'] = utils.json_to_sheet(students);

    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });

    saveAs(blob, `importStudentsFileExample-${new Date().toISOString()}${EXCEL_EXTENSION}`);
  }

  public downloadClientsFile() {
    const workbook: WorkBook = { SheetNames: [], Sheets: {} };

    const clients = [
      {
        fullname: 'Liam Smith',
        nickname: ' Liam',
        email: 'liamsmith@example.com',
      },
    ];

    workbook.SheetNames.push('clients');
    workbook.Sheets['clients'] = utils.json_to_sheet(clients);

    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });

    saveAs(blob, `importClientsFileExample-${new Date().toISOString()}${EXCEL_EXTENSION}`);
  }

  public async importClients(e: Event) {
    try {
      const user = await this.authService.getUser();
      const org = get(user, 'organization', {});

      const target = e.target as HTMLInputElement;
      const files = target.files;

      if (!files?.length) {
        this._dialog.open(AlertDialogComponent, {
          width: '400px',
          data: 'No file selected. Please select a file to import.',
        });
        return false;
      }

      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await firstValueFrom(
        this.accountController.accountControllerAccountImport(UserTypeEnum.Client, org.id, file),
      );

      return response;
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error importing clients: ' + error.message,
      });
    }
  }

  public exportClients() {
    if (!this.clients?.length) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'No clients to export',
      });
      return;
    }

    try {
      const clientsData = this.clients.map((client) => {
        return {
          fullname: get(client, 'givenName', '') + ' ' + get(client, 'familyName', ''),
          nickname: get(client, 'nickname', ''),
          email: get(client, 'email', ''),
        };
      });

      this.excelService.exportData(clientsData, 'clients', 'ExportClients');
    } catch (error) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'Error exporting clients: ' + error.message,
      });
    }
  }

  public async importStudentsForFile(e: any, client: Client) {
    if (!e.target.files.length) {
      this._dialog.open(AlertDialogComponent, {
        width: '400px',
        data: 'No file selected. Please select a file to import.',
      });
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event: any) => {
      const students = [];
      const fileContent = event.target?.result as ArrayBuffer;
      const workbook: WorkBook = read(fileContent, { type: 'binary' });
      const sheetNames = workbook.SheetNames;

      sheetNames.map((sheetName) => {
        const sheet: WorkSheet = workbook.Sheets[sheetName];
        const sheetData = utils.sheet_to_json(sheet);

        const checkIfExistsError = validateStudentImportData(sheetData as Student[]);
        if (checkIfExistsError) {
          this._dialog.open(AlertDialogComponent, {
            width: '400px',
            data: checkIfExistsError.error,
          });
          return;
        }

        if (sheetName === 'students' && !checkIfExistsError) {
          for (const data of sheetData) {
            if (!data['nickname']) {
              data['nickname'] = data['givenName'].split(' ')[0];
            }
            data['image'] = '';
            data['archived'] = false;
            students.push(data);
          }

          const promiseList = [];
          for (const student of students) {
            promiseList.push(this._rest.post('/student/' + client.id, { student: student }));
          }

          Promise.all(promiseList);
        }
      });
    };
    reader.readAsArrayBuffer(file);
    return true;
  }
}
